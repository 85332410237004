import  {useState} from 'react';
import './Navbar.css';
import {Link, NavLink} from 'react-router-dom';
import {GiHamburgerMenu} from 'react-icons/gi';
import {RiCloseFill} from 'react-icons/ri'
import MobileNav from '../MobileNav/MobileNav';

function Navbar () {
  const [active, setActive] = useState(false);

  const hamburgerIcon = <GiHamburgerMenu 
                          className='mobile-menu' 
                          onClick={() => setActive(!active)}
                          />

                          
  const closeIcon = <RiCloseFill 
  className='mobile-menu' 
  onClick={() => setActive(!active)}
  />;



  return (
    <>
    <div className="Navbar">
      <div className="left-side">
        <div className="logo">
          <Link to='/'>
            <img src="https://res.cloudinary.com/teb/image/upload/v1671707373/PennyTree_Full_Logo_Lemon-White_1_b3q2a8.png" alt="" />
          </Link>
        </div>
        <ul className="nav-links">
        <li><NavLink to="/" class
        style={({isActive}) => {return {color:isActive ? 'rgba(76, 200, 0, 1)': ''}}}
        >Home</NavLink></li>
        <li><NavLink 
        to="/about"
        style={({isActive}) => {return {color:isActive ? 'rgba(76, 200, 0, 1)': ''}}}
        >Company</NavLink></li>
        <li><NavLink to="/how-it-works"
         style={({isActive}) => {return {color:isActive ? 'rgba(76, 200, 0, 1)': ''}}}
        >How it works</NavLink></li>
        <li><a href="https://intercom.help/pennytree/en" target='blank'>FAQ</a></li>
        <li><a href="https://web.mypennytree.com/" className='webapp-btn'>Login</a></li>
        {/* <li><NavLink to="/contact"
         style={({isActive}) => {return {color:isActive ? 'rgba(76, 200, 0, 1)': ''}}}
        >Contact</NavLink></li> */}
      </ul>
      </div> 
      {active ? closeIcon : hamburgerIcon }
      
      
    </div>
    {active && <MobileNav/>}
    </>
  );
}

export default Navbar;
