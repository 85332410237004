import React from 'react'
import NewFeatured from '../featured/NewFeatured'
import './NewBanner.css'

function NewBanner() {
  return (
    <div className='new-banner'>
        <div className="banner-contents">
        <img src="https://res.cloudinary.com/teb/image/upload/v1672469767/Mask_group_2_acs8nq.png" alt="" className='new-banner-img1' />
      <div className="new-banner-text">
        <h1>Building the future of <span>retail banking </span>for you</h1>
        <img src="https://res.cloudinary.com/teb/image/upload/v1671004943/Handdrawn_vector_fvblaz.png" alt="" />
        <p>Experience simpler and more personalised banking for you and your business.</p>
        <a href='#download-page'>Get our app</a>
      </div>
        </div>
        <NewFeatured/>
        <img src="https://res.cloudinary.com/teb/image/upload/v1671725271/Hexagon_1_tuczkl.png" alt="" className='banner-hexagon' />
    </div>
  )
}

export default NewBanner
