import React from 'react';
import './Terms.css';

function Terms () {
  return (
    <div className="terms">
      <h1>Terms And Conditions – PennyTree Business Limited</h1>
      <h1>Terms Of Use</h1>
      <div className="term-section">
        <h1>Introduction:</h1>
        <p>
          Welcome to PennyTree. these terms and conditions of use constitute a legally binding agreement
          by and between you, whether personally or on behalf of an entity (“you”) and PennyTree
          business limited doing business as PennyTree (“PennyTree”, “company”, we”, “us”, or “our”),
          concerning your access to and use of the PennyTree app our https://ww.mypennytree.com
          website and as well as any media form, media channel, mobile web or mobile application
          (collectively “service”, “services”, “app”) whether as a guest or registered user. We are registered
          in Nigeria and have our registered office at 9a Oriwu street, Lekki phase 1, Lagos state, Nigeria
        </p>
        <p>
          You agree that by accessing our services, you have read, understood, and agreed to be bound by
          all these terms of service.
        </p>
        <p>
          If you do not agree with all of these terms of use, then you are prohibited from using the app
          and must discontinue use immediately.
        </p>
        <p>
          Supplemental terms and conditions or documents that may be posted on the app from time to
          time are hereby expressly incorporated herein by reference. We reserve the right, in our sole
          discretion, to make changes or modifications to these terms of service from time to time. we will
          alert you about any changes by updating the “last updated” date of these terms of service, and
          you waive any right to receive specific notice of each such change. Please ensure that you check
          the applicable terms every time you use our app so that you understand which terms apply.
        </p>
        <p>
          You will be subject to and will be deemed to have been made aware of and to have accepted, the
          changes in any revised terms of service by your continued use of the app after the date such
          revised terms of use are posted.
        </p>
        <p>
          The app is intended for users who are at least 18 years old, however, persons under the age of 18
          are also permitted and eligible to use or register for the app but must strictly adhere to the terms
          of use.
          <p>
            To use our services, you must:<br />
            ● Be 18 and/or above as earlier mentioned. Users under 18 years of age must have
            permission from, and be directly supervised by, their parent or guardian to use the
            PennyTree app.<br />
            ● Have an account with at least one of the financial institutions in Nigeria
            {' '}
            <br />
            ● Provide all the information required at sign up such as full name, phone number, email
            address, and any other information that may be requested from time to time.
            <br />

          </p>
        </p>
      </div>
      <div className="term-section">
        <h1>Terms of Use</h1>
        <p>
          This platform's goal is to make finance easy for you. The funds you credit into your PennyTree
          account via your virtual account number, debit card and USSD are used as triggered by you to
          invest in the categories or commodities you want to invest in. Upon maturity of the investment,
          and personal vault your funds (principal and interest) are moved to your account for you to
          either reinvest or transfer to your bank account.
          withdrawn to the bank account you attached to your PennyTree account, used to pay bills on
          PennyTree, and locked in a personal vault for a specific duration.

        </p>
      </div>
      <div className="term-section">
        <h1>Scope of PennyTree Services</h1>
        <p>
          PennyTree is a neo-bank that provides you with financial services in the most convenient way.
          You now have access to individual services like bank transfers, bill payments, and personal vaults
          for savings, where you can earn up 15% per annum.
        </p>
        <p>
          With the Merchant Services offering, you can open a business banking account with a click of a
          button, receive payments from clients and vendors, generate invoices and receipts, download a
          stamped bank statement in real time and transfer up to NGN5 million daily.
        </p>
        <p>
          What more? We have introduced our Crowdfunding Platform, where you can invest in your
          favourite brands from as low as NGN5,000 and earn a competitive return on investment.

        </p>

      </div>
      <div className="term-section">
        <h1>Data Protection</h1>
        <p>
          By opening this account, you agree that PennyTree should process your personal data to:
          ● Provide products and services to you in terms of this agreement and any other product
          services for which you may apply<br />
          ● Carry out market research and analysis to identify potential market opportunities and
          trends to enable us to serve you better.<br />
          ● We shall have no liability to you for any loss or corruption of any such data, and you
          hereby waive any right of action against us from any such loss or corruption of such data.
        </p>
      </div>
      <div className="term-section">
        <h1>Users</h1>
        <p>
          A user is referred to as an individual who uses our mobile application for personal, business,
          evaluation, research, and (or) educational purposes. while a client is an individual or entity who
          signs up on PennyTree, therefore, allowing his/her finance to be managed by PennyTree, and
          who makes payment via the mobile application bound by the terms of use, and any additional
          terms by agreeing to these terms and conditions, you are to which you agree when you create
          and fund a savings plan make payment and withdrawal at any time.

        </p>
        <p>
          The following will not be allowed:<br />
          Illegal or fraudulent activities/services.<br />
          ● Use of personal information and details meant to purposely mislead, misrepresent and
          defraud other parties.<br />
          ● Use of PennyTree and/or app’s products for activities that are prohibited by any laws,
          regulations, and/or third parties terms of service, as well as for any marketing activity
          that negatively affects the app’s relationship with users or partners.
          <br />

        </p>
      </div>
      <div className="term-section">
        <h1>User Registration:</h1>
        <p>
          Only registered users may be allowed to carry out activities on PennyTree. This registration is
          free. in registering for an account, you represent and warrant:<br />
          ● That you agree to provide us with accurate, complete, and updated information and must
          not create an account for fraudulent or misleading purposes. note that some of our
          services are only for fully registered users.<br />
          ● You are solely responsible for any activity on your account after registration and for
          maintaining the confidentiality and security of your password and transaction pin. you are
          responsible for all use of your account and password.<br />
          ● you will not access the PennyTree service through automated or non-human means
          whether through a bot, script, or otherwise;<br />
          ● You will not use PennyTree service for any illegal or unauthorized purpose; and,
          {' '}
          <br />
          ● Your use of PennyTree service will not violate any applicable law or regulations.
        </p>
        <p>
          Users undertake to comply with PennyTree standards, which are a set of behavior rules and
          guidelines, applicable to PennyTree and the marketplace in addition to these terms of service, as
          updated from time to time.<br />
          You agree that we may access, store, process, and use any personal information and data that you
          provided at sign-up following the privacy policy and your choices/settings.
        </p>

      </div>
      <div className="term-section">
        <h1>Users Information</h1>
        <p>
          We collect users personal information such as full names, phone number, and email address to
          create a PennyTree profile for our users.
        </p>
      </div>
      <div className="term-section">
        <h1>Users Contact</h1>
        <p>
          Some of the services provided on the PennyTree app require users to get direct access to their
          contact list for success purchases of bills. Please note that the access to your contact list only
          gives PennyTree the right to buy utilities through the phone number of the selected contact.
        </p>
      </div>
      <div className="term-section">
        <h1>Users Feedback</h1>
        <p>
          By submitting feedback about PennyTree services, you agree that we can use and share such
          feedback for any purpose without further permission and without compensation to you.
          you will retain the full ownership of your feedback and as such we are not liable for any
          statements or representation in your feedback provided by you in using PennyTree and you
          expressly agree to exonerate us from all responsibility and to refrain from any legal action against
          us regarding your feedback.
        </p>
      </div>
      <div className="term-section">
        <h1>User Review Guidelines:</h1>
        <p>
          PennyTree may provide areas on the site or third-party sites to leave reviews and ratings. when
          posting such reviews and ratings, you must adhere to the following guidelines:
          <br />
          ● you should have firsthand experience with PennyTree service being reviewed
          <br />
          ● your review should not contain discriminatory references based on religion, race, gender,
          national origin, age, marital status, sexual orientation, or disability,
          <br />
          ● your review should not contain a reference to illegal activity,
          <br />
          ● you should not be affiliated with competitors while posting reviews,
          <br />
          ● you will not post any false or misleading statements; and<br />
          ● you will not organize a campaign encouraging others to post reviews, whether positive or
          negative.<br />
          ● you must provide accurate, current and complete information during the registration
          process a your PennyTree Account profile page information up-to-date at all times.
          <br />
          ● you are responsible for safeguarding the password that you use to access the service and
          for any activities or actions under your password, whether your password is with our
          service or a third-party service.<br />
        </p>
      </div>
      <div className="term-section">
        <h1>Commission and Penalty Fee:</h1>
        <p>
          By consenting to these terms and conditions, you would not be able to liquidate from your
          invesment on PennyTree. this implies that you would not be able to withdraw from your
          investment, however, you would be charged a commission fee of fifty-three Naira seventy-five
          kobo (₦53.75) if you complete a bank transfer on PennyTree.
        </p>
      </div>
      <div className="term-section">
        <h1>KYC Levels:</h1>
        <p>
          “know your customer” is a requirement by the central bank of Nigeria to establish the legitimacy
          of a customer’s identity in order to prevent identity theft, financial fraud, money laundering,
          terrorist financing, and other risk factors.<br />
          ● KYC 1 (basic) - full name, email address, and phone number<br />
          ● KYC 2 - BVN verification<br />
          ● KYC 3 - identity verification (national identification number, driver’s license
          number)
        </p>
        <h1>Notification</h1>
        <p>
          By agreeing to these terms and conditions, you agree to be receiving text messages (SMS), push
          notifications, and emails from us.
        </p>
      </div>
      <div className="term-section">
        <h1>Language And Communication:</h1>
        <p>
          The official language of the PennyTree app is the English language. We can also communicate
          with emojis.

        </p>
      </div>
      <div className="term-section">
        <h1>Third-Party Product And Services</h1>
        <p>
          PennyTree may provide third-party services using its app (third-party services). PennyTree
          disclaims all liability, including express or implied warranties, whether oral or written, for such
          third-party services. the user acknowledges that no representation has been made by PennyTree
          as to the fitness of the third-party services for the user’s intended purpose. third-party services
          and content on PennyTree service do not imply approval or endorsement thereof by us. If you
          decide to use any third-party services through PennyTree services, you do so at your own risk,
          and you should be aware of these terms and conditions of PennyTree. services no longer govern
          you. any third-party service is used at your own risk and as such you should review the applicable
          terms and conditions including privacy policies and data gathering practices of such third party
          service.
          you agree and acknowledge that we do not endorse the product or services offered through our
          listed third party and you shall hold us harmless from any losses to you or harm caused to you in
          any way from any third-party service.

        </p>
      </div>
      <div className="term-section">
        <h1>Customer Support</h1>
        <p>
          Users may report complaints on the products to customer support that may be in violation of
          PennyTree’s terms of service. PennyTree will respond to clear and complete notices of the
          alleged infringement.

        </p>
      </div>
      <div className="term-section">
        <h1>Disputes And Resolutions</h1>
        <p>
          We encourage our users to try and settle conflicts amongst themselves. If for any reason this fails
          after using the resolution center or if you encounter non-permitted usage on the app, users can
          contact PennyTree's customer support department for assistance. If there is a legal dispute, users
          agree that it will take place in state or federal high courts under the relevant Nigerian laws. you
          agree that these terms of use shall be governed by and interpreted in accordance with the laws of
          the federation of the federal republic of Nigeria.
          you agree and acknowledge that we do not endorse the product or services offered through our
          listed third party and you shall hold us harmless from any losses to you or harm caused to you in
          any way from any third-party service.

        </p>
      </div>
      <div className="term-section">
        <h1>User-Generated Contributions</h1>
        <p>
          PennyTree services does not offer users to submit or post content. We may provide you the
          opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast
          content and materials to use or on PennyTree site, including but not limited to writing, video,
          audio, graphics, suggestions, comments or other materials (collectively “contributions”). such
          contributions may be viewable by other users or the general public through third-party
          websites/services. Hence, any assistance you transmit may be treated in accordance with
          PennyTree’s privacy policy.<br />
          when contributing, you thereby represent and warrant that:<br />
          I. Your creation, distribution, transmission, public display, or performance, and the
          accessing, downloading, or copying of your contributions do not and will not infringe
          the proprietary rights, including but not limited to the copyright, patent, trademark,
          trade secret, or moral rights of any third party.<br />
          II. You are the sole creator and owner or have the necessary license, right, consent,
          releases, and permission to use and to authorize us, PennyTree, and other users of
          PennyTree service to use your contribution in any manner contemplated by
          PennyTreeand these terms and conditions of use.<br />
          III. You have the written consent, release, and/or permission of every identifiable
          individual person in your contributions to use the name or likeness of every such
          identifiable person to enable inclusion and use of your contributions in any manner
          contemplated by PennyTree and these terms and conditions of use.
          IV. Your contributions are not false or misleading.<br />
          V. Your contributions are not unsolicited or unauthorized advertising, promotional
          materials, spam, or other forms of solicitation.<br />
          VI. Your contributions are not obscene, lewd, lascivious, filthy, violent, harassing,
          libelous, slanderous, or otherwise objectionable (as determined by PennyTree).
          <br />
          VII. Your contributions do not ridicule, mock, disparage, intimidate, or abuse any person
          or entity.<br />
          VIII. Your contributions are not used to harass or threaten (in the legal sense of those
          terms (any other person or entity and to

        </p>
      </div>
      <div className="term-section">
        <h1>Pennytree Account</h1>
        <p>
          By signing up on PennyTree, you have agreed to be assigned a wallet; users are permitted to fund
          their wallets with their personal bank debit cards using our third-party card processing gateway
          and bank transfer to the provided bank account (provided on the user’s PennyTree dashboard).
          the use of a third-party card is only accepted upon approval by the card owner. stolen cards and
          fraudulent usage of a third-party card are not permitted. In cases like this, if found guilty, the
          account holder would be required to face the law as provided by the federal republic of Nigeria.
          to withdraw from your wallet, PennyTree might at her own discretion ask for documents to
          further verify the owner of the account holder, and/or card owner.
          you can transfer, pay bills and withdraw from your Penn wallet. The restrictions on this are
          dependent on your KYC level.

        </p>
      </div>
      <div className="term-section">
        <h1>Reporting Violations</h1>
        <p>
          If you come across anything that may violate our terms of service and/or our community
          standards, you should report it to us through the appropriate channels created to handle those
          issues as outlined in our terms of service.

        </p>
      </div>
      <div className="term-section">
        <h1>Violations</h1>
        <p>
          Users may receive a warning to their account or have their account restricted for violations of
          our terms of service and/or our community standards or any user misconduct reported to our
          trust and safety team. a warning will be displayed for such users on the app. warnings may limit
          account activity, but can lead to your account becoming permanently disabled based on the
          severity of the violation except for such violations

        </p>
      </div>
      <div className="term-section">
        <h1>Non-Permitted Usage</h1>
        <p>
          The Authenticity Of Pennytree Profile – You may not create a false identity on PennyTree,
          misrepresent your identity, create a PennyTree profile for anyone other than yourself (a real
          person), or use or attempt to use another user’s account or information; your profile
          information, including your description, BVN, bank details, location, etc., while may be kept
          anonymous, must be accurate and complete and may not be misleading, illegal, offensive or
          otherwise harmful. PennyTree reserves the right to require users to go through a verification
          process in order to use the app (whether by using id, phone, camera, etc.).
        </p>
      </div>
      <div className="term-section">
        <h1>Fraud/Unlawful Use </h1>
        <p>
          You may not use PennyTree for any unlawful purposes or to conduct
          illegal activities.

        </p>
      </div>
      <div className="term-section">
        <h1>Proprietary Restrictions</h1>
        <p>
          PennyTree services and mobile application, including its general layout, look and feel, design,
          information, content, and other materials available thereon, are exclusively owned by PennyTree
          and protected by copyright, trademark, and other intellectual property laws. users have no right,
          and specifically agree not to do the following with respect to the app or any part, component or
          extension of the app (including its mobile applications):<br />
          I. Copy, transfer, adapt, modify, distribute, transmit, display, create derivative works, and
          publish or reproduce them, in any manner.<br />
          II. Reverse assembles, decompile, reverse engineer or otherwise attempt to derive its
          source code, underlying ideas, algorithms, structure, or organization.
          <br />
          III. Remove any copyright notice, identification, or other proprietary notices.
          IV. Use automation software (bots), hacks, modifications (mods), or any other
          unauthorized third-party software designed to modify the app.<br />
          V. Attempt to gain unauthorized access to, interfere with, damage or disrupt the app or
          the computer systems or networks connected to the app.<br />
          VI. Circumvent, remove, alter, deactivate, degrade or thwart any technological measure
          or content protections of the app.<br />
          VII. Use any robot, spider, crawler, or other automatic devices, process, software, or
          queries that intercept, “mines,” scrapes, or otherwise access the app to monitor,
          extract, copy or collect information or data from or through the app, or engage in
          any manual process to do the same<br />
          VIII. Introduce any viruses, trojan horses, worms, logic bombs, or other materials that are
          malicious or technologically harmful into our systems.<br />
          IX. Use the app in any manner that could damage, disable, overburden or impair the app,
          or interfere with any other user's enjoyment of the app or<br />
          X. Access or use the app in any way not expressly permitted by these terms of service.
          Users also agree not to permit or authorize anyone else to do any of the foregoing

        </p>
      </div>
      <div className="term-section">
        <h1>Disclaimer and Limit of Liability</h1>
        <p>
          You recognize that PennyTree cannot guarantee the investment results you might achieve using
          the investment information and financial insights it offers, and that PennyTree is not liable. All
          investments come with the possibility and understanding that you could lose money. Your
          deliberate enrollment in and acceptance of these Terms of Use are requirements for your
          decision to use our investing services. You acknowledge and accept that PennyTee is only for
          educational reasons and not for the purpose of offering legal, tax, or financial planning advice.
          You acknowledge that PennyTree is merely one of many tools you may use as part of a thorough
          investing strategy, and that you, not PennyTree, are in charge of your own financial research and
          investment decisions.

        </p>
      </div>
      <div className="term-section">
        <h1>Investment Risk</h1>
        <p>
          Despite the fact that we give illustrative interest rates and returns for some plans on our
          platform, you understand that the value of any investment and the assets may decline due to
          market value fluctuations, and as a result, the assets' or interest rates' value (as appropriate) in
          your User Account may increase or decrease depending on the state of the market. You affirm
          that neither we nor anyone on our behalf has given you any oral or written assurances that your
          investment goal will be met or that the value of any assets in your User Account won't decrease.
        </p>
      </div>
      <div className="term-section">
        <h1>Termination</h1>
        <p>
          A. You agree that PennyTree in its sole discretion, may modify, suspend or terminate your
          account (or any part thereof) or use of the service and remove and discard any content
          within the service, for any reason, including, without limitation, for lack of use or if
          PennyTree believes that you have violated or acted inconsistently with the letter or spirit
          of these terms of service.<br />
          B. Any suspected fraudulent, abusive, or illegal activity relating to the use of PennyTree
          services may be grounds for termination of your use of PennyTree service and such
          activity may be referred to appropriate law enforcement authorities for further
          prosecution as deemed necessary by PennyTree.<br />
          C. PennyTree may also in its sole discretion and at any time discontinue providing the
          service, or any part thereof, with or without notice. you agree that any termination of
          your access to the service under any provision of this terms of service can be affected
          without prior notice and acknowledge and agree that PennyTree may immediately
          deactivate or delete your account and all related information and files in your account
          and/or restrict any further access to such files or the service.<br />
          D. You may end this agreement and terminate your use of PennyTree service at any time but
          only subject to the maturity of all and any investment currently subscribed to on the
          PennyTree application.<br />
          E. Further, you agree that PennyTree will not be liable to you or any third party for any
          termination of your access to the service.
        </p>
      </div>
      <div className="term-section">
        <h1>Limitations Of Liability</h1>
        <p>
          In no event will we or our directors, employees, or agents be liable to you or any third party for
          any direct, indirect, consequential, exemplary, incidental, special, or punitive damages arising
          from the use of PennyTree services, even if we have been advised of the possibility of such
          damages.

        </p>
      </div>
      <div className="term-section">
        <h1>
          Declaration
        </h1>
        <p>
          By consenting to this agreement, you agree that you have read and understood the above stated
          terms and conditions and that you now own a PennyTree account. you agree to abide by the
          above-listed terms and conditions, which govern our operations. you also agree that the
          information supplied is true and correct. by opening an account with us, we may from time to
          time carry out regular identity and fraud prevention checks.

        </p>
      </div>
      <div className="term-section">
        <h1>
          PennyTree Indemnity Form.
        </h1>
        <p>
          For being a client of PennyTree Business Limited ("Service Provider") I confirm that I hereby
          authorize PennyTree Business Limited to effect any and all transactions relating to my account
          held with PennyTree Business Limited on the basis of Electronic Mail (email) or any internet
          device(s) reasonably assessed to be issued by me in accordance with the email address or
          telephone number I submitted PennyTree in the account opening form.

        </p>
        <p>
          I irrevocably authorize PennyTree to make any payments and comply with all instructions
          contained in such email or SMS without any reference to or further authority from me and
          without enquiry into the justification therefore or validity thereof and agree that PennyTree may
          assume the authenticity thereof and that any payment which PennyTree shall make or
          instructions which PennyTree may comply with in accordance or purporting to be in accordance
          with such email instructions shall be binding upon me and shall be accepted by me as conclusive
          evidence that PennyTree was authorized by me to make such payment or comply with such
          demand.
        </p>
        <p>
          The fact that any instruction may later be shown to be in any way false, incomplete, inaccurate,
          delayed, erroneous, unauthorized, or otherwise not authentic, should not be an impediment to
          the rights of PennyTree hereunder.
        </p>
        <p>
          I further hereby fully indemnify the PennyTree against all direct and indirect losses, damages,
          actions, or claims whatsoever suffered by myself or PennyTree as a result of PennyTree acting on
          the basis of the said instructions including any costs and charges incurred in recovering damages
        </p>
        <p>
          I agree that should I or PennyTree suffer any loss as more fully enumerated above, I shall be
          liable for the full amount of such loss.
        </p>
        <p>
          I agree that this indemnity shall subsist and remain valid and binding throughout the duration of
          my relations with PennyTree Business Limited.
        </p>
        <p>
          This document shall be governed by the laws of the Federal Republic of Nigeria and I hereby
          execute it as a Deed and cause it to be delivered by the acceptance of this indemnity form.
        </p>
      </div>

    </div>
  );
}

export default Terms;
