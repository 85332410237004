import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import '../components/Banner/Banner.css'
import AboutBanner from '../components/AboutBanner/AboutBanner';
import NewFooter from '../components/Footer/NewFooter';

function About () {
  return (
    <div className='AboutPage'>
      <Navbar />
      <AboutBanner/>
      <NewFooter/>
    </div>
  );
}

export default About;
