import React from 'react'
import './pagenotfound.css'

function PageNotFound() {
  return (
    <div className='no-page'>
      <h2>404 Page not found</h2>
      <a href="/" className='get-app-btn'>Return Home</a>
    </div>
  )
}

export default PageNotFound
