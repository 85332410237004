import React from 'react'
import './NewMerchant.css'

function NewMerchant() {
  return (
    <div className='new-merchant'>
      <h1>Premium Banking for <span>Individuals</span> and <span>Merchants.</span></h1>
      <p>PennyTree is a regulated and licensed financial services platform built to provide simple and better banking services for you, whether as an individual or as a business owner.</p>
      <div className="new-merchant-container">
        <div className="new-merchant-box">
        <div className="half-circle">
            <div className="half-circle-box">
            <img src="https://res.cloudinary.com/teb/image/upload/v1671008829/shuttle_1_pilpfj.svg" alt="" />
            </div>
            
        </div>
            <h1>Get Started</h1>
            <p>Download the PennyTree App and start transacting.</p>
            <div className="green-line"></div>
        </div>
        <div className="new-merchant-box">
        <div className="half-circle">
            <div className="half-circle-box">
            <img src="https://res.cloudinary.com/teb/image/upload/v1671009099/add_1_obk4lm.svg" alt="" />
            </div>
            
        </div>
            <h1>Sign Up</h1>
            <p>Create your PennyTree account and get verified in less than 1 minute.</p>
            <div className="green-line"></div>
        </div>
        <div className="new-merchant-box">
        <div className="half-circle">
            <div className="half-circle-box">
            <img src="https://res.cloudinary.com/teb/image/upload/v1671009095/diagram_1_uttuub.svg" alt="" />
            </div>
            
        </div>
            <h1>Benefits</h1>
            <p>Earn up to 15% on funds locked in your wallet or vault. Save towards a goal using our target savings feature.</p>
            <div className="green-line"></div>
        </div>
      </div>
    </div>
  )
}

export default NewMerchant