import React, { useEffect, useState } from 'react'

const DownloadScreen = () => {
  const [device, setDevice] = useState(null)
  const deviceCheck = () => {
    if (/Android/i.test(navigator.userAgent)) {
      setDevice('andriod')
    } else if (/iPhone|iPad|Macintosh|iPod/i.test(navigator.userAgent)) {
      setDevice('ios')
    } else {
      setDevice(null)
    }
  }

  useEffect(() => {
    deviceCheck()
  }, [device])

  return (
    <>
      <main>
        {device === 'andriod' ? (
          (window.location =
            'https://play.google.com/store/apps/details?id=com.pennysafe.pennytree')
        ) : device === 'ios' ? (
          (window.location = 'https://apps.apple.com/app/id1602953102')
        ) : (
          <></>
        )}
      </main>
    </> 
  )
}

export default DownloadScreen