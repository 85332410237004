import React from 'react'
import './Download.css'

function Download() {
  return (
    <div className='download-page' id='download-page'>
        <img src="https://res.cloudinary.com/teb/image/upload/v1671047562/Hexagon_Yellow_oxu5yu.png" alt=""  className='hexagon-yellow'/>
        <img src="https://res.cloudinary.com/teb/image/upload/v1671047562/Hexagon_Green_azq3ho.png" alt=""  className='hexagon-green'/>
            <div className="banking-box-left">
                <h1>Banking just got better and easier</h1>
                <p>Download the PennyTree app today and enjoy our various features</p>
                {/* <Link to='/downloads' target='_blank' className='download-btn'>Download now</Link> */}
                <div className="download-box">
                  <a href='https://play.google.com/store/apps/details?id=com.pennysafe.pennytree&hl=en_US&gl=US' target='blank'>
                  <img src="https://res.cloudinary.com/teb/image/upload/v1672136200/Group_963_upagay.svg" alt="" />
                  </a>
                  <a href='https://apps.apple.com/in/app/pennytree/id1602953102' target='blank'>
                  <img src="https://res.cloudinary.com/teb/image/upload/v1672136201/Group_964_c2ep6o.svg" alt="" />
                  </a>
                </div>
            </div>
            <div className="banking-box-right">
                <img src="https://res.cloudinary.com/teb/image/upload/v1672140590/Group_1000004645_xkipdt.png" alt="" className='download-phone-img' />
            </div>
        </div>
  )
}

export default Download
