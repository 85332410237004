import React from 'react'
import ReactDOM from 'react-dom/client'
import Home from './pages/Home'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import About from './pages/About'
import HowItWorks from './pages/HowItWorks'
import Terms from './components/Terms/Terms'
import ScrollToTop from './components/ScrollToTop'
import DownloadScreen from './components/DownloadScreen'
import PageNotFound from './pages/PageNotFound'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <ScrollToTop>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        {/* <Route path='/features' element={<FeaturePage />} /> */}
        {/* <Route path='/register' element={<RegisterPage />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/dashboard' element={<Dashboard />} /> */}
        <Route path='/how-it-works' element={<HowItWorks />} />
        <Route path='*' element={<PageNotFound />} />
        {/* <Route path='/login' element={<LoginPage />} />
        <Route path='/register' element={<RegisterPage />} /> */}
        <Route path='/policy' element={<Terms />} />
        <Route path='/downloads' element={<DownloadScreen />} />
      </Routes>
    </ScrollToTop>
  </BrowserRouter>
)
