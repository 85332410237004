import React from 'react'
import './NewFeatured.css'

function NewFeatured() {
  return (
    <div className='new-featured'>
      <h1>As featured in:</h1>
      <div className="logo-box">
    
        <a href='https://sunnewsonline.com/fintech-firm-gets-secs-crowdfunding-licence/?amp' target='blank'>
          <img src="https://res.cloudinary.com/teb/image/upload/v1709139927/The_Sun.svg_may2hu.png" alt=""  className='featured-paper-img'/>
        </a>
        <a href=' https://www.vanguardngr.com/2024/02/fintech-firm-pennytree-acquires-crowdfunding-licence/' target='blank'>
          <img src="https://res.cloudinary.com/teb/image/upload/v1709139927/Vanguard_m636fp.png" alt=""  className='featured-paper-img'/>
        </a>
      <a href='https://www.thisdaylive.com/index.php/2024/02/14/pennytree-acquires-crowdfunding-licence-from-securities-exchange-commission' target='blank'>
          <img src="https://res.cloudinary.com/teb/image/upload/v1674149372/this-day_j3b6u6.png" alt=""  className='featured-paper-img'/>
          </a>
          {/* <a href='https://guardian.ng/news/pennytree-launches-new-app-to-enhance-retail-banking-services/' target='blank'>
          <img src="https://res.cloudinary.com/teb/image/upload/v1670502171/guardian-removebg-preview_e5blox.png" alt="" className='featured-paper-img'/>
        </a>
        <a href='https://businessday.ng/news/article/pennytree-in-pact-with-vfd-to-offer-seamless-payment-system-to-businesses/' target='blank'>
          <img src="https://res.cloudinary.com/teb/image/upload/v1676721360/Businessday-Logo..._lj14pz.png" alt="" className='featured-paper-img'/>
          </a> */}
              
          {/* <a href='https://punchng.com/fintech-firm-launches-retail-banking-app/' target='blank'>
          <img src="https://res.cloudinary.com/teb/image/upload/v1674183436/punch-logo-500x179-1_jxo0hs.png" alt="" className='featured-paper-img'/>
        </a> */}
      
             
            

        </div>
    </div>
  )
}

export default NewFeatured
