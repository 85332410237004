import React from 'react'
import './MeetMerchant.css'

function MeetMerchant() {
  return (
    <div className='meet-merchants'>
      <h1>Meet Our Merchants</h1>
      <img src="https://res.cloudinary.com/teb/image/upload/v1671004943/Handdrawn_vector_fvblaz.png" alt="" className='meet-merchants-img' />
      <div className="meet-merchants-container">
        <div className="meet-merchant-box">
            <img src="https://res.cloudinary.com/teb/image/upload/v1672682812/Onopemipo_1_ywppem.svg" alt="" />
            <h1>Daramola Onopemipo</h1>
            <p>COO - Arabella Beauty Affairs</p>
        </div>
        <div className="meet-merchant-box">
            <img src="https://res.cloudinary.com/teb/image/upload/v1672682762/gizmo_2_1_emynuh.svg" alt="" />
            <h1>Ibiyinka Lawal</h1>
            <p>Founder - Gizmo Stores</p>
        </div>
        <div className="meet-merchant-box">
            <img src="https://res.cloudinary.com/teb/image/upload/v1672682713/Lela_2_1_u2k89q.svg" alt="" />
            <h1>Olubukola Adekunle</h1>
            <p>Founder - Lela’s Foodmart</p>
        </div>
        <div className="meet-merchant-box">
            <img src="https://res.cloudinary.com/teb/image/upload/v1672682804/Thamespice_1_citg1i.svg" alt="" />
            <h1>Temitope Modupe</h1>
            <p>Founder - Thames Spice Haven</p>
        </div>
        <div className="meet-merchant-box">
            <img src="https://res.cloudinary.com/teb/image/upload/v1672682778/Cake_priest_1_c5avfg.svg" alt="" />
            <h1>Kema Abuede (Cakepriest)</h1>
            <p> Founder - Conspicuous Cakes</p>
        </div>
        <div className="meet-merchant-box">
            <img src="https://res.cloudinary.com/teb/image/upload/v1672682773/Adebadol_2_1_txvxdn.svg" alt="" />
            <h1>Gbenga Adetunji</h1>
            <p>Founder & Creative Director - Adebadol</p>
        </div>
        <div className="meet-merchant-box">
            <img src="https://res.cloudinary.com/teb/image/upload/v1672682362/BFH_2_1_k4zgn0.svg" alt="" />
            <h1>Binta Doga</h1>
            <p>Founder - BFH Designs</p>
        </div>
      
     
      
     
    
      </div>
    </div>
  )
}

export default MeetMerchant
