import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './Carousel.css'

function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} fade>
      {/* <Carousel.Item>
        <img
          className="d-block"
          style={{height: 550}}
          src="https://res.cloudinary.com/teb/image/upload/v1672585085/BFH_Design_1_ahi8zy.png"
          alt="First slide"
        />
        <Carousel.Caption>
          <h4>“Using PennyTree has been an interesting experience for my business. In addition to the features on the app that help make my business run smoothly, I have been able to connect and collaborate with other business owners in the PennyTree Merchant Community.” - <span>BINTA (Founder, BFH Designs)</span></h4>
        </Carousel.Caption>
      </Carousel.Item> */}
      <Carousel.Item>
        <img
          className="d-block"
          style={{height: 550}}
          src="https://res.cloudinary.com/teb/image/upload/v1671261957/Adebadol_i082i7.png"
          alt="Second slide"
        />

        <Carousel.Caption>
        <h4>“PennyTree merchant services have really been helping my brand as regarding keeping structure in place, sending invoices and issuing receipts. I feel these helps my business have a good perception towards my customers” - <span>GBENGA ADETUNJI (Founder & Creative Director, Adebadol)</span></h4>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block"
          style={{height: 550}}
          src="https://res.cloudinary.com/teb/image/upload/v1672427513/Thamespice_f7m2so.png"
          alt="Third slide"
        />

        <Carousel.Caption>
        <h4>“As part of our strategy to increase our sales, we joined the PennyTree merchant community. I remember the week we joined the community, we recorded a high sales volume; and that is because of the value chain marketing opportunities that is available to businesses in the community ” - <span>TEMITOPE MODUPE OKEOWO (Founder, Thames Spice Haven)</span></h4>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block"
          style={{height: 550}}
          src="https://res.cloudinary.com/teb/image/upload/v1672585004/Arabella_1_ninsjh.png"
          alt="Fourth slide"
        />

        <Carousel.Caption>
          <h4>“PennyTree has been helping us solve the bit of accountability, book-keeping, invoicing and finance reconciliation. At the tap of a button, customers get their receipts and invoices; We at the backend also get ours. This saves a lot of time and manpower. Amazing stuff!” - <span>DARAMOLA ONOPEMIPO (COO, Arabella Beauty Affairs)</span></h4>
        </Carousel.Caption>
      </Carousel.Item>
      {/* <Carousel.Item>
        <img
          className="d-block"
          style={{height: 550}}
          src="https://res.cloudinary.com/teb/image/upload/v1672427924/Images_gzvdsb.png"
          alt="Fifth slide"
        />

        <Carousel.Caption>
          <h4>“In the aspect of structure, PennyTree have been working hand in hand to solve the problem of corporate governance and making other aspects thrive independently; therefore in my absence, my business can run effectively.” -<span> IBIYINKA LAWAL(Founder, Gizmo Stores)</span></h4>
        </Carousel.Caption>
      </Carousel.Item>  */}
      <Carousel.Item>
        <img
          className="d-block"
          style={{height: 550}}
          src="https://res.cloudinary.com/teb/image/upload/v1672428097/Images_1_fqsglg.png"
          alt="Sixth slide"
        />

        <Carousel.Caption>
          <h4>“PennyTree merchant community is one of the best ways PennyTree has helped me. The opportunity to meet and connect with business owners like myself, means that I can always get support for my business” - <span>OLUBUKOLA ADEKUNLE (Founder, Lela’s Foodmart)</span></h4>
        </Carousel.Caption>
      </Carousel.Item> 
    </Carousel>
    
  );
}

export default ControlledCarousel