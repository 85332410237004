import React from 'react'
import './community.css'

function Community() {
  return (
    <div className='community'>
        <h1>Join our <br/> Merchant Community</h1>
        <img src="https://res.cloudinary.com/teb/image/upload/v1671004943/Handdrawn_vector_fvblaz.png" alt="" />
        <div className="community-box">
            <img src="https://res.cloudinary.com/teb/image/upload/v1672682820/ayo_1_vaeqlg.svg" alt="" />
            <div className="community-box-text" id='community'>
                <h1>Connect and Collaborate in the PennyTree Merchant Community</h1>
                <p>Join thousands of merchants like you using PennyTree to scale their businesses. Connect and collaborate with other merchants in our community and discover new ways to grow your business. Take advantage of the value chain marketing opportunities within our ecosystem. <span>#impactthroughcommunity</span></p>
                <div className="download-box">
                  <a href='https://play.google.com/store/apps/details?id=com.pennysafe.pennytree&hl=en_US&gl=US' target='blank'>
                  <img src="https://res.cloudinary.com/teb/image/upload/v1672136200/Group_963_upagay.svg" alt="" />
                  </a>
                  <a href='https://apps.apple.com/in/app/pennytree/id1602953102' target='blank'>
                  <img src="https://res.cloudinary.com/teb/image/upload/v1672136201/Group_964_c2ep6o.svg" alt="" />
                  </a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Community
