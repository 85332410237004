import React from 'react'
import './Carousel.css'
import ControlledCarousel from './CarouselComp'

function Carousel() {
  return (
    <div className="carousel-container">
        <div className='picture-carousel'>
            <ControlledCarousel/>
        </div>
    </div>
  )
}

export default Carousel
