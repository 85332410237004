import React from 'react'
import './NewFooter.css'
import {
    FaFacebookSquare,
    FaTwitterSquare,
    FaLinkedin,
    FaInstagram,
    FaWhatsapp,
  } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function NewFooter() {
  return (
    <div className='new-footer' id='footer-box'>
      <img src="https://res.cloudinary.com/teb/image/upload/v1669093011/Pennytree_Full_Logo_Green-White_bvwvuh.png" alt="" className='footer-logo' />
      <div className="footer-links-container">
        <div className="footer-links">
            <h3>About</h3>
            <Link to='/about'>
              <p>Company</p>
            </Link>
        </div>
        <div className="footer-links">
            <h3>Support</h3>
            <a href="https://intercom.help/pennytree/en">
            <p>FAQ</p>
            </a>
            <p>support@mypennytree.com</p>
            <p><a href='https://wa.me/message/IKUOBMIJ2E7SP1'>+2349042300216 (WhatsApp Only)</a></p>
        </div>
        <div className="footer-links">
            <h3>Legal</h3>
            <Link to='/policy'>
              <p>Terms of service</p>
            </Link>
        </div>
        <div className="footer-links">
            <h3>Follow us:</h3>
            <div className="social-icons">
          <a href="https://www.facebook.com/mypennytree" target='blank'>
            <FaFacebookSquare className="soc-icon" />
          </a>
          <a href="https://twitter.com/mypennytree?s=21&t=QiXq2XYZAHKDXmDZ7GH2Lw" target='blank'>
            <FaTwitterSquare className="soc-icon" />
          </a>
          <a href="https://www.linkedin.com/company/pennytree-services/" target='blank'>
            <FaLinkedin className="soc-icon" />
          </a>
          <a href="https://instagram.com/mypennytree?igshid=Zjc2ZTc4Nzk=" target='blank'>
            <FaInstagram className="soc-icon" />
          </a>

          <a href='https://wa.me/message/IKUOBMIJ2E7SP1'  target='blank'>
            <FaWhatsapp className="soc-icon" />
          </a>

        </div>
        </div>
      </div>
      <div className="bottom-footer">
        <img src="https://res.cloudinary.com/teb/image/upload/v1672641259/MBC_1_vskxu6.png" alt="" />
        <div className="app-icon-container" id='app-download'>
          <h3>Our Mobile app is available</h3>
        <div className="app-icons">
          <a href='https://play.google.com/store/apps/details?id=com.pennysafe.pennytree&hl=en_US&gl=US' target='blank'>
            <img src="https://res.cloudinary.com/teb/image/upload/v1672142943/Group_963_2_dciz03.png" alt="" className='app-icon'/>
          </a>
          <a href='https://apps.apple.com/in/app/pennytree/id1602953102' target='blank'>
            <img src="https://res.cloudinary.com/teb/image/upload/v1672142943/Group_964_2_iev4sj.png" alt="" className='app-icon' />
          </a>
        </div>
        </div>
        <img src="https://res.cloudinary.com/teb/image/upload/v1671046670/Group_1000004644_sczzdi.png" alt="" />
        <p>&copy; PennyTree 2023</p>
      </div>
    </div>
  )
}

export default NewFooter
