import React from 'react'
import './MobileNav.css'
import {Link, NavLink} from 'react-router-dom'

function MobileNav() {
  return (
    <div className='mobile-nav'>
        <ul className="mobile-nav-links">
        <li><NavLink to="/"
        style={({isActive}) => {return {color:isActive ? 'rgba(76, 200, 0, 1)': ''}}}
        >Home</NavLink></li>
        <li><NavLink to="/about"
        style={({isActive}) => {return {color:isActive ? 'rgba(76, 200, 0, 1)': ''}}}
        >About us</NavLink></li>
        {/* <li><NavLink to="/features"
        style={({isActive}) => {return {color:isActive ? 'rgba(76, 200, 0, 1)': ''}}}
        >Features</NavLink></li> */}
        <li><NavLink to="/how-it-works"
        style={({isActive}) => {return {color:isActive ? 'rgba(76, 200, 0, 1)': ''}}}
        >How it works</NavLink></li>
        <li><a href="https://intercom.help/pennytree/en" target='blank'>FAQ</a></li>
        <div className="mobile-cta">
        {/* <NavLink to="/login" 
        style={({isActive}) => {return {color:isActive ? 'rgba(76, 200, 0, 1)': ''}}}
        className='login-btn'>Login</NavLink>
        <NavLink to="/register"
        style={({isActive}) => {return {backgroundColor:isActive ? '#fff': ''}}}
        className="btn1">Open an Account</NavLink> */}
      </div>
      </ul>
      <Link>
        <img src="https://res.cloudinary.com/teb/image/upload/v1671046670/app_download_y7ttzz.png" alt="" />
      </Link>
    </div>  
  )
}

export default MobileNav