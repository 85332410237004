import React from 'react'
import './Aboutbanner.css'

function AboutBanner() {
  return (
    <>
    <div className='about-banner'>
      <div className="banner-left">
        <h1>About Us</h1>
          <p>PennyTree is a neo-bank that provides you with financial services in the most convenient way. You can now access individual services like bank transfers, bill payments,
            personal vaults and target savings, where you can earn up to 15% per annum. With the Merchant Services offering, you can open a business banking account with a click of a button,
            receive payments from clients and vendors, generate invoices and receipts, download a stamped bank statement in real time and transfer up to NGN5 million daily</p>
          <p>
            We are licensed by the Securities and Exchange Commission (SEC) of Nigeria as a crowdfunding platform to act as a digital intermediary that connects individuals or organisations seeking to raise funds for their projects or businesses.
            With this, our goal is to democratize the funding process, making investment opportunities accessible to anyone with an internet connection as we continue to play a role in the ecosystem of innovation and entrepreneurship,
            facilitating the realization of creative projects, startups and social causes in Africa and beyond.
          </p>
      </div>
      <div className="banner-right">
        <img src="https://res.cloudinary.com/teb/image/upload/v1672682725/IMG_7669_1_uymoeb.svg" alt="" />
      </div>
    </div>
    {/* <div className="vis-mission">
        <div className="vis-mis-box">
            <div className="half-circle">
            <div className="half-circle-box">
            <img src="https://res.cloudinary.com/teb/image/upload/v1671133920/binocular_1_wkbdux.png" alt="" />
            </div>
            </div>
            <h1>Our Vision</h1>
            <p>To make humanity seamless through finance.</p>
            <div className="green-line"></div>
        </div>
        <div className="vis-mis-box">
            <div className="half-circle">
            <div className="half-circle-box">
            <img src="https://res.cloudinary.com/teb/image/upload/v1671133920/target-2_1_x95p2d.png" alt="" />
            </div>
            </div>
            <h1>Our Mission</h1>
            <p>We are integrating finance and lifestyle as one-component.</p>
            <div className="green-line"></div>
        </div>
    </div> */}
    {/* <div className="core-values">
        <h1>Our Core Values</h1>
        <img src="https://res.cloudinary.com/teb/image/upload/v1671004943/Handdrawn_vector_fvblaz.png" alt="" />
        <div className="core-values-container">
            <div className="core-values-box">
            <div className="core-values-header">
                <div className="green-dot"></div>
                <p>Service excellence</p>
            </div>
            <h3>We provide premium value and service by ensuring our work is up to par.</h3>
            </div>
            <div className="core-values-box">
            <div className="core-values-header">
                <div className="green-dot"></div>
                <p>People Centric</p>
            </div>
            <h3>It starts with us; We honor commitments to our customers and to one another.</h3>
            </div>
            <div className="core-values-box">
            <div className="core-values-header">
                <div className="green-dot"></div>
                <p>Innovation</p>
            </div>
            <h3>Innovation is the core value on which the PennyTree App is built. </h3>
            </div>
        </div>
    </div> */}
    <div className="ceo">
        <div className="ceo-left">
          <h1>Directors' Statement</h1>
          <h4>Committed to building the future of retail banking for you</h4>
          <p>Just like you, we are familiar with the challenges of personal and business banking and are committed to making the experience simpler for you.</p>
          <p>We are building the future of retail banking to give you a dependable platform where you can save or invest your money securely, create a bank account for your business, and access the needed advisory services while collaborating with entrepreneurs in our community to achieve business growth. We are a regulated and licensed financial technology platform, empowered to provide the best financial services for you both as an individual and a business owner in the most simple and easily accessible way.</p>
          <p>Come on this journey with us as we build the future of retail banking designed for you.</p>
        </div>
        <div className="ceo-right">
          <img src="https://res.cloudinary.com/teb/image/upload/v1672683010/Ayo_and_abayomi_2c_1_2_z2ofmv.svg" alt="" />
        </div>
    </div>
    </>
  )
}

export default AboutBanner
