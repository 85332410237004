import React from 'react';
import AboutUs from '../components/HowItWorks/HowItWorks';
import Navbar from '../components/Navbar/Navbar';
import NewFooter from '../components/Footer/NewFooter';

function HowItWorks () {
  return (
    <div className='How-it-page'>
      <Navbar />
      <AboutUs />
      <NewFooter/>
    </div>
  );
}

export default HowItWorks;
