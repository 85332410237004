import React from 'react'
import './HowItWorks.css'

function AboutUs() {
  return (
    <div className='how-it-works'>
        <h1>Designed for <span>ease</span> and <span>Simplicity</span></h1>
        <div className="how-it-works-container">
            <div className="how-it-works-box">
                <div className="half-circle-box">
                <div className="half-circle">
                    <h2>1</h2>
                </div>
                </div>
                <h1>Open an account</h1>
                <p>Sign up and verify your account using your BVN to start enjoying limitless banking.</p>
                <div className="green-line"></div>
            </div>
            <div className="how-it-works-box">
                <div className="half-circle-box">
                <div className="half-circle">
                    <h2>2</h2>
                </div>
                </div>
                <h1>Funds transfer</h1>
                <p>Transfer funds to merchant accounts and other PennyTree users for free.</p>
                <div className="green-line"></div>
            </div>
            <div className="how-it-works-box">
                <div className="half-circle-box">
                <div className="half-circle">
                    <h2>3</h2>
                </div>
                </div>
                <h1>Start saving</h1>
                <p>Lock your funds in the Personal Vault and earn up to 12% on your money</p>
                <div className="green-line"></div>
            </div>
            <div className="how-it-works-box">
                <div className="half-circle-box">
                <div className="half-circle">
                    <h2>4</h2>
                </div>
                </div>
                <h1>Create Business Account</h1>
                <p>Verify your BVN and NIN to create a merchant account for your business.</p>
                <div className="green-line"></div>
            </div>
            <div className="how-it-works-box">
                <div className="half-circle-box">
                <div className="half-circle">
                    <h2>5</h2>
                </div>
                </div>
                <h1>Generate receipts & Invoices</h1>
                <p>Generate and send receipts and invoices to your customers from the PennyTree app.</p>
                <div className="green-line"></div>
            </div>
            <div className="how-it-works-box">
                <div className="half-circle-box">
                <div className="half-circle">
                    <h2>6</h2>
                </div>
                </div>
                <h1>Invest In Brands Businesses</h1>
                <p>Start investing in your favourite brands and businesses and get paid with interest.</p>
                <div className="green-line"></div>
            </div>
        </div>
    </div>
  )
}

export default AboutUs