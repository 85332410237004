import React from 'react'
import './NewFeatures.css'

function NewFeatures() {
  return (
    <div className='new-features'>
        <div className="new-features-heading">
        <h1>Features</h1>
        <p>Explore premium banking services designed for <span>ease</span> and <span>simplicity</span></p>
        </div>
      <div className="new-features-container">
        <div className="merchant-services">
            <div className="merchant-header">
                <div className="green-dot"></div>
                <p>Merchant Services</p>
            </div>
            <div className="merchant-body add-gap">
                <div className="merchant-body-det">
                <div className="icon">
                    <img src="https://res.cloudinary.com/teb/image/upload/v1671053344/Vector_ujurpn.svg" alt="" />
                </div>
                <div className="heading">
                    <h1>Payments & Tranfers Money</h1>
                </div>
                <div className="body">
                    <p>Receive payments to your PennyTree merchant account and send money to PennyTree users or any bank.</p>
                </div>
                </div>
                <div className="merchant-body-det">
                <div className="icon">
                    <img src="https://res.cloudinary.com/teb/image/upload/v1671053584/Vector_3_lnbxqp.svg" alt="" />
                </div>
                <div className="heading">
                    <h1>Generate Receipt and Invoice</h1>
                </div>
                <div className="body">
                    <p>Generate receipts and invoices for your customers to ensure transparency and record keeping</p>
                </div>
                </div>
                <div className="merchant-body-det">
                <div className="icon">
                    <img src="https://res.cloudinary.com/teb/image/upload/v1671053584/Group_hbqfjb.svg" alt="" />
                </div>
                <div className="heading">
                    <h1>Keep Track of your Finances:</h1>
                </div>
                <div className="body">
                    <p>Track your finances to note trends and make better business decisions.</p>
                </div>
            </div>
        </div>
            
        </div>
        <img src="https://res.cloudinary.com/teb/image/upload/v1672132541/Pennytree_mobile_5_1_1_lw4wxq.png" alt="" className='new-feature-phone' />
        <div className="merchant-services">
            <div className="merchant-header">
                <div className="green-dot"></div>
                <p>Individual Services</p>
            </div>
            <div className="merchant-body">
            <div className="merchant-body-det">
                <div className="icon">
                    <img src="https://res.cloudinary.com/teb/image/upload/v1671053584/Group_hbqfjb.svg" alt="" />
                </div>
                <div className="heading">
                    <h1>Investment Marketplace</h1>
                </div>
                <div className="body">
                    <p>Access investment opportunities in PennyTree’s Investment Marketplace</p>
                </div>
            </div>
                <div className="merchant-body-det">
                <div className="icon">
                    <img src="https://res.cloudinary.com/teb/image/upload/v1671053344/Vector_ujurpn.svg" alt="" />
                </div>
                <div className="heading">
                    <h1>Send and Receive Money</h1>
                </div>
                <div className="body">
                    <p>Send and receive money to other PennyTree users via Penn-2-Penn or other banks via Penn-2-Bank</p>
                </div>
                </div>
                <div className="merchant-body-det">
                <div className="icon">
                    <img src="https://res.cloudinary.com/teb/image/upload/v1671053584/Vector_3_lnbxqp.svg" alt="" />
                </div>
                <div className="heading">
                    <h1>Buy Airtime/Pay bills</h1>
                </div>
                <div className="body">
                    <p>Pay all your utility bills with ease</p>
                </div>
            </div>
            
        </div>
            
            
        </div>
      </div>
    </div>
  )
}

export default NewFeatures
