import React from 'react'
import Navbar from '../components/Navbar/Navbar'
import '../components/Banner/Banner.css'
import NewBanner from '../components/Banner/NewBanner'
import NewMerchant from '../components/Merchant/NewMerchant'
import MeetMerchant from '../components/MeetMerchants/MeetMerchant'
import Community from '../components/community/Community'
import NewFooter from '../components/Footer/NewFooter'
import Download from '../components/Download/Download'
import NewFeatures from '../components/NewFeatures/NewFeatures'
import Carousel from '../components/Carousel/Carousel'
import './pages.css'
function Home() {
  return (
    <div className='Homepage'>
        <Navbar/>
        <NewBanner/>
        <NewMerchant/>
        <NewFeatures/>
        <Download/>
        <Carousel/>
        <MeetMerchant/>
        <Community/>
        <NewFooter/>
    </div>
  )
}

export default Home